import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import React from 'react';
import withAuthRequired from 'src/lib/auth';
import Button from 'src/lib/components/forms/Button';
import { Text } from 'src/lib/components/Text';
import Layout from 'src/lib/layout/Layout';

type NotFoundPageProps = {};

const NotFoundPage = ({}: NotFoundPageProps) => {
  const router = useRouter();
  return (
    <Layout>
      <div
        css={css`
          display: flex;
          justify-content: center;
          padding-top: 30vh;
          height: 90vh;
        `}
      >
        <div>
          <Text variant="H2">Hmmm, we can&rsquo;t find this page</Text>
          <Button
            css={css`
              margin-top: 30px;
            `}
            onClick={() => router.push('/cards')}
            label="Take me back"
          />
        </div>
      </div>
    </Layout>
  );
};

export default withAuthRequired(NotFoundPage, 'notFoundPage');
